import React, { FC, useState } from 'react';
import { Form, Header, Message, Dropdown, Button } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { isEqual } from 'lodash';

import {
  JoinDa,
  Party,
  Site,
  VoterClass,
  leoOptionsDataObject,
} from '../../API';
import CountrySelect from '../CountrySelect';
import GenerateLogCsvButtons from './GenerateLogCsvButtons';
import GenerateLogCountButtons from './GenerateLogCountButtons';
import VotingStateAndJurisdictionSelect from '../VotingStateAndJurisdictionSelect';
import ColumnsSelect from '../ColumnsSelect';

const year = new Date().getUTCFullYear();
const startDateUtc = new Date(`${year}-01-01`);
startDateUtc.setTime(
  startDateUtc.getTime() + startDateUtc.getTimezoneOffset() * 60 * 1000,
);

const INITIAL_FORM_STATE = {
  startDate: startDateUtc,
  endDate: new Date(),
  countries: [],
  votingStates: [],
  congressionalDistricts: [],
  leoJurisdictions: [],
  voterClasses: [],
  joinDa: null,
  party: null,
  site: null,
  recordType: 'COMPLETED',
  columnsSelector: null,
};

interface CsvLogExportProps {
  leoOptionsData: leoOptionsDataObject[];
}

const CsvLogExport: FC<CsvLogExportProps> = ({ leoOptionsData }) => {
  const [startDate, setStartDate] = useState(INITIAL_FORM_STATE.startDate);
  const [endDate, setEndDate] = useState(INITIAL_FORM_STATE.endDate);
  const [countries, setCountries] = useState<string[]>(
    INITIAL_FORM_STATE.countries,
  );
  const [votingStates, setVotingStates] = useState<string[]>(
    INITIAL_FORM_STATE.votingStates,
  );
  const [leoJurisdictions, setLeoJurisdictions] = useState<string[]>(
    INITIAL_FORM_STATE.leoJurisdictions,
  );
  const [congressionalDistricts, setCongressionalDistricts] = useState<
    string[]
  >(INITIAL_FORM_STATE.congressionalDistricts);
  const [voterClasses, setVoterClasses] = useState<VoterClass[]>(
    INITIAL_FORM_STATE.voterClasses,
  );
  const [joinDa, setJoinDa] = useState<JoinDa | null>(
    INITIAL_FORM_STATE.joinDa,
  );
  const [party, setParty] = useState<Party | null>(INITIAL_FORM_STATE.party);
  const [site, setSite] = useState<Site | null>(INITIAL_FORM_STATE.site);
  const [recordType, setRecordType] = useState(INITIAL_FORM_STATE.recordType);

  const [columnsSelector, setColumnsSelector] = React.useState<string>(null);
  const [columnsToggled, setColumnsToggled] = React.useState<string[]>(null);

  const resetForm = () => {
    setStartDate(INITIAL_FORM_STATE.startDate);
    setEndDate(INITIAL_FORM_STATE.endDate);
    setCountries(INITIAL_FORM_STATE.countries);
    setVotingStates(INITIAL_FORM_STATE.votingStates);
    setLeoJurisdictions(INITIAL_FORM_STATE.leoJurisdictions);
    setCongressionalDistricts(INITIAL_FORM_STATE.congressionalDistricts);
    setVoterClasses(INITIAL_FORM_STATE.voterClasses);
    setJoinDa(INITIAL_FORM_STATE.joinDa);
    setParty(INITIAL_FORM_STATE.party);
    setSite(INITIAL_FORM_STATE.site);
    setRecordType(INITIAL_FORM_STATE.recordType);
    setColumnsSelector(INITIAL_FORM_STATE.columnsSelector);
  };

  const formHasChanges = !isEqual(INITIAL_FORM_STATE, {
    startDate,
    endDate,
    countries,
    votingStates,
    leoJurisdictions,
    congressionalDistricts,
    joinDa,
    party,
    site,
    recordType,
  });

  return (
    <Form>
      <Header as="h2">VFA Reporting Data Export</Header>
      <Message>
        No personally identifiable information (PII) is included.
      </Message>
      <Form.Field>
        <label>Start date</label>
        <SemanticDatepicker
          value={startDate}
          onChange={(_evt, data) => setStartDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field>
        <label>End date</label>
        <SemanticDatepicker
          value={endDate}
          onChange={(_evt, data) => setEndDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Group grouped>
        <label>Record type</label>
        <Form.Radio
          label="Completed"
          value="COMPLETED"
          checked={recordType === 'COMPLETED'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
        <Form.Radio
          label="Incomplete"
          value="INCOMPLETE"
          checked={recordType === 'INCOMPLETE'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
        <Form.Radio
          label="All"
          value="ALL"
          checked={recordType === 'ALL'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
      </Form.Group>
      <Form.Field>
        <label>Country</label>
        <CountrySelect
          multiple
          search
          value={countries}
          selection
          onChange={(_evt, data) => setCountries(data.value)}
        />
      </Form.Field>

      <VotingStateAndJurisdictionSelect
        leoOptionsData={leoOptionsData}
        setVotingStates={setVotingStates}
        votingStates={votingStates}
        setLeoJurisdictions={setLeoJurisdictions}
        leoJurisdictions={leoJurisdictions}
      />

      {/* <Form.Field> */}
      {/*  <label>Congressional District</label> */}
      {/*  <CongressionalDistrictSelect */}
      {/*    search */}
      {/*    multiple */}
      {/*    value={congressionalDistricts} */}
      {/*    selection */}
      {/*    onChange={(_evt, data) => setCongressionalDistricts(data.value)} */}
      {/*  /> */}
      {/* </Form.Field> */}
      <Form.Field>
        <label>Voter Class</label>
        <Dropdown
          clearable
          multiple
          value={voterClasses}
          options={[
            {
              key: VoterClass.INTEND_TO_RETURN,
              value: VoterClass.INTEND_TO_RETURN,
              text: 'Intend To Return',
            },
            {
              key: VoterClass.MILITARY,
              value: VoterClass.MILITARY,
              text: 'Military/Active Duty',
            },
            {
              key: VoterClass.MIL_SPOUSE,
              value: VoterClass.MIL_SPOUSE,
              text: 'Military Spouse',
            },
            {
              key: VoterClass.NEVER_RESIDED,
              value: VoterClass.NEVER_RESIDED,
              text: 'Never Lived in US',
            },
            {
              key: VoterClass.UNCERTAIN_RETURN,
              value: VoterClass.UNCERTAIN_RETURN,
              text: 'Uncertain Return',
            },
          ]}
          selection
          onChange={(_evt, data) => setVoterClasses(data.value as VoterClass[])}
        />
      </Form.Field>
      <Form.Field>
        <label>Join DA</label>
        <Dropdown
          clearable
          value={joinDa}
          options={[
            { key: 'ALL', value: null, text: '' },
            { key: JoinDa.TRUE, value: JoinDa.TRUE, text: 'True' },
            { key: JoinDa.FALSE, value: JoinDa.FALSE, text: 'False' },
            {
              key: JoinDa.ALREADY_A_MEMBER,
              value: JoinDa.ALREADY_A_MEMBER,
              text: 'Already a member',
            },
          ]}
          selection
          onChange={(_evt, data) => setJoinDa(data.value as JoinDa)}
        />
      </Form.Field>
      <Form.Field>
        <label>Party</label>
        <Dropdown
          clearable
          value={party}
          options={[
            { key: 'ALL', value: null, text: '' },
            {
              key: Party.DEMOCRATIC,
              value: Party.DEMOCRATIC,
              text: 'Democratic',
            },
            {
              key: Party.REPUBLICAN,
              value: Party.REPUBLICAN,
              text: 'Republican',
            },
            {
              key: Party.OTHER,
              value: Party.OTHER,
              text: 'Other Party',
            },
            {
              key: Party.PREFER_NOT_TO_ANSWER,
              value: Party.PREFER_NOT_TO_ANSWER,
              text: 'Prefer not to answer',
            },
            {
              key: Party.NO_PARTY_PREFERENCE,
              value: Party.NO_PARTY_PREFERENCE,
              text: 'No party preference',
            },
          ]}
          selection
          onChange={(_evt, data) => setParty(data.value as Party)}
        />
      </Form.Field>
      <Form.Field>
        <label>Website version</label>
        <Dropdown
          clearable
          value={site}
          options={[
            { key: 'NO ANSWER', value: null, text: '' },
            {
              key: Site.STANDARD,
              value: Site.STANDARD,
              text: 'VFA Standard',
            },
            {
              key: Site.SPANISH,
              value: Site.SPANISH,
              text: 'VFA Spanish',
            },
            {
              key: Site.STUDENT,
              value: Site.STUDENT,
              text: 'Students',
            },
            {
              key: Site.BETA,
              value: Site.BETA,
              text: 'Beta',
            },
          ]}
          selection
          onChange={(_evt, data) => setSite(data.value as Site)}
        />
      </Form.Field>
      <Form.Field>
        <GenerateLogCountButtons
          startDate={startDate}
          endDate={endDate}
          countries={countries}
          congressionalDistricts={congressionalDistricts}
          leoJurisdictions={leoJurisdictions}
          voterClasses={voterClasses}
          joinDa={joinDa}
          party={party}
          site={site}
          votingStates={votingStates}
          recordType={recordType}
        />
      </Form.Field>

      <ColumnsSelect
        targetQuery="anonymized"
        radioValue={columnsSelector}
        setRadioValue={setColumnsSelector}
        columnsValue={columnsToggled}
        setColumnsValue={setColumnsToggled}
      />

      <Form.Field>
        <GenerateLogCsvButtons
          startDate={startDate}
          endDate={endDate}
          countries={countries}
          congressionalDistricts={congressionalDistricts}
          leoJurisdictions={leoJurisdictions}
          voterClasses={voterClasses}
          joinDa={joinDa}
          party={party}
          site={site}
          votingStates={votingStates}
          recordType={recordType}
          columnsSelector={columnsSelector}
          columnsToggled={columnsToggled}
        />
      </Form.Field>
      {formHasChanges && (
        <Form.Field>
          <Button onClick={resetForm}>Reset form</Button>
        </Form.Field>
      )}
    </Form>
  );
};

export default CsvLogExport;
