import React, { FC, useContext } from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import UserContext from '@/components/UserContext';
import CsvDataExport from '@/components/CsvDataExport';
import CsvLogExport from '@/components/CsvLogExport';
import { leoOptionsDataObject } from '../../API';

interface ExportTabProps {
  leoOptionsData: leoOptionsDataObject[];
  lastRefreshDate: string;
}

const DataTab: FC<ExportTabProps> = ({ leoOptionsData, lastRefreshDate }) => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = 'VFA Dashboard (Export)';
    }
  }, []);

  const userContext = useContext(UserContext);
  const groups: string[] =
    userContext?.signInUserSession?.accessToken?.payload?.['cognito:groups'] ??
    [];

  return (
    <Grid stackable columns={3}>
      {groups.includes('Admins') && (
        <Grid.Column>
          <Segment>
            <CsvDataExport leoOptionsData={leoOptionsData} />
          </Segment>
          <Segment>
            <div>
              Data last refreshed:{' '}
              {`${new Date(lastRefreshDate).toUTCString()}`}
            </div>
          </Segment>
        </Grid.Column>
      )}
      <Grid.Column>
        <Segment>
          <CsvLogExport leoOptionsData={leoOptionsData} />
        </Segment>
        <Segment>
          <div>
            Data last refreshed: {`${new Date(lastRefreshDate).toUTCString()}`}
          </div>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default DataTab;
